import ws1 from '../assets/ws1.png'
import pws5 from '../assets/pws5.pdf'

import ws2 from '../assets/ws2.png'
import ws3 from '../assets/ws3.png'
import ws4 from '../assets/ws4.png'
import ws8 from '../assets/ws8.png'
import ws5 from '../assets/ws5.png'
import ws6 from '../assets/ws6.png'

import pws6 from '../assets/pws6.pdf'

import React from "react";

const WorksheetsAd = () => {
  // Static list of worksheet images
  const worksheets = [
    { image: ws1 },
    { image: ws8 },
    { image: ws3 },
    { image: ws4 },
    { image: ws5, pdf: pws5 },
    { image: ws6, pdf: pws6 },
    { image: ws2 },
    // Add more images here
  ];

  return (
    <div className="max-w-screen-lg mx-auto p-4">
      <h2 className="text-center text-3xl font-bold mb-6">Instant Worksheets, Like these ...</h2>
      
      {/* Horizontal scroller */}
      <div className="flex overflow-x-auto space-x-4">
        {worksheets.map((worksheet, index) => (
          <div key={index} className="min-w-[250px] flex-shrink-0 border p-4 rounded-lg shadow-lg text-center">
             <a href={worksheet.pdf} target="_blank" rel="noopener noreferrer">
            <img
              src={worksheet.image}
              alt={`Worksheet ${index + 1}`}
              className="mb-4 w-full h-96 object-cover rounded-md"
            />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorksheetsAd;
