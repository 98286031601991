import React from 'react'

const handleButtonClick = () => {
  window.open('https://app.theworksheep.com', '_blank');
};



export const Analytics = () => {
  return (
    <div className='w-full bg-white py-16 px-4' id='howto'>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8'>
            
        <iframe
            className='w-full h-400 mx-auto my-4'
            width="500"
            height="229"
            src="https://www.youtube.com/embed/co4koppeTUs"
            title="Introduction to Worksheep"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>
            
            
        
        
        <div className='flex flex-col justify-center'>
            <p className='text-[#00df9a] font-bold'> HOW DOES IT WORK?</p>
            <h1 className='md:text-4xl sm:text3xl text-2xl font-bold py-2'>Get your worksheet designed in seconds</h1>
            <p className='mb-4'> 
            Take a look at the demo and start simplifying your teaching journey today! As seen in the video, Worksheets can be generated for Your classroom needs to enhance math learning.
            </p>
            <button className='bg-black w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-white' onClick={handleButtonClick} >Start Now!</button>
        </div>

        </div>
    </div>
  )
}
